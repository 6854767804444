/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const HandbookTopimage = () => {
  const HandbookTopimages = useStaticQuery(graphql`
      query HandbookTopimages {
        top: file(absolutePath: {regex: "/assets/images/products/companyhandbooks/CompanyHandbookbanner-img.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="scontent-wrap introduction">
        <Row className="sp-5">
          <div className="pb-5 col-12">
            <Link className="reversearrow" to="/services">
              View all our products
              <svg
                className="styled__Arrow-d87459-0 bQLhSh"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </div>
          <Col lg="7">
            <h1>Company Handbooks.</h1>
            <p>We work with you to get your entire company’s or departments standard operating procedures all in one place. This light-weight living document becomes an ever-improving central source of truth for your team’s knowledge, processes and documents. We’ve run every part of our own company like this for years, the results are truly magical and lasting.</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Image className="img-fluid" sizes={HandbookTopimages.top.childImageSharp.sizes} alt="We’ve used a handbook to run our company for years. It’s the only way I’ve found to get everyone singing from the same hymn book, and even more so now with remote work becoming the new normal."/>
          </Col>
        </Row>
        <Row className="spb-10 border-bottom">
          <Col className="testimonial">
            <h2>
            We’ve used a handbook to run our company for years. It’s the only way I’ve found to get everyone singing from the same hymn book, and even more so now with remote work becoming the new normal.
            </h2>
            <span>Anton Moulder, Urbian Managing Partner</span>
          </Col>
        </Row>
      </Container>
    )
  }
export default HandbookTopimage

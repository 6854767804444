/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const implementedTop = () => {
  const implementedTopImages = useStaticQuery(graphql`
      query implementedTopImages {
        spw: file(absolutePath: {regex: "/assets/images/products/companyhandbooks/SPWHandbookThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1580) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        urbian: file(absolutePath: {regex: "/assets/images/products/companyhandbooks/UrbianHandbookThumbnail.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1580) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="spb-10 Casestudies-s">
        <Container>
          <Row>
            <Col sm="12" lg="5" className="titlef sp-5">
            	<h2>Some of the Handbooks we’ve implemented</h2>
            </Col>
           </Row>
           <Row>
              <Col sm="6">
                <Link to="/spw">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={implementedTopImages.spw.childImageSharp.sizes} alt="Sanlam uses a Handbook to better manage 300 portfolio managers"/>
                  </div>
                  <small class="clientname spw">Sanlam Private wealth</small>
                  <h6 className="h3">Sanlam uses a Handbook to better manage 300 portfolio managers.</h6>
                  <p>Over 300 Sanlam Private Wealth portfolio managers use a Handbook to provide a world-class client service experience to it’s high-net-worth clients. By centralising all the processes, access to up-to-date document templates and report generating functionality, portfolio managers are able to spend more time on servicing clients and less time on repetitive admin tasks.</p>
                </Link>
              </Col>
              <Col sm="6">
                <Link to="/standardbank">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={implementedTopImages.urbian.childImageSharp.sizes} alt="Urbian has been using a Handbook to run our entire company for years."/>
                  </div>
                  <small class="clientname urbian">Urbian</small>
                  <h6 className="h3">Urbian has been using a Handbook to run our entire company for years.</h6>
                  <p>Starting as a Google Slide deck for internal teams only to eventually creating a public facing Handbook optimised for mobile, Urbian would not be the company it is today if not for our Company Handbook.</p>
                </Link>
              </Col>
            </Row>
        </Container>
      </div>
    )
  }
export default implementedTop

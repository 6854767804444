/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
// import { Link } from 'gatsby'
import './styled.css'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const CompanyHandBookHowdothis = () => {
  const CompanyHandBookHowdothisImages = useStaticQuery(graphql`
      query CompanyHandBookHowdothisImages {
        sop: file(absolutePath: {regex: "/assets/images/products/companyhandbooks/holdingbook.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        manage: file(absolutePath: {regex: "/assets/images/products/companyhandbooks/ManageYourself.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        improvement: file(absolutePath: {regex: "/assets/images/products/companyhandbooks/OngoingImprovement.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="howdo_this altgrid spt-10">
        <Row className="sp-5">
          <Col>
            <h2>What we help you do</h2>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="5">
            <h3>Centralise all your standard operating procedures</h3>
            <p>We simply come alongside your team and put down in black and white what is mostly inside managers heads.</p>
            <p>The outcome is a simple, clear, methodical digitisation of your business processes and best practices, office policies, engineering practices, employee contact info — any other important info — in one central location.</p>
            <p>Use the app on your phone for those on-the-go or from the browser of your choice.</p>
          </Col>
          <Col md="6" lg="6" className="offset-lg-1">
            <Image className="img-fluid" sizes={CompanyHandBookHowdothisImages.sop.childImageSharp.sizes} alt="Centralise all your standard operating procedures"/>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col lg="6">
            <Image className="img-fluid" sizes={CompanyHandBookHowdothisImages.manage.childImageSharp.sizes} alt="Get your company to begin managing itself"/>
          </Col>
          <Col lg="5" className="offset-sm-1">
            <h3>Get your company to begin managing itself</h3>
            <p>The minute your standard operating procedures are digitised the results happen quickly. Most of the time this results in less email.</p>
            <p>Less time spent answering the same repetitive questions, less time spent directing new (and sometimes old) employees where to find things and less time reminding others of a change in process.</p>
            <p>Our Handbooks help anyone in the company (whether a new hire or an old faithful) know exactly what they should do, how they should do it and who they should do it with. We allow you to insert notes with the relevant links to any existing document templates needed to get the specific job done.</p>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="5">
            <h3>Create a system of ongoing improvement</h3>
            <p>The world changes fast and so does your business. We help you build in a self-regulating mechanism to keep your company as agile as possible.</p>
            <p>No-one is better suited to improving your company than the person who does the work everyday. We help you empower them with the ability to point out an outdated process or old document and put forward a ‘change request’. The relevant manager can review the request and instantly update it.</p>
          </Col>
          <Col md="6" lg="6" className="offset-lg-1">
            <Image className="img-fluid" sizes={CompanyHandBookHowdothisImages.improvement.childImageSharp.sizes} alt="Create a system of ongoing improvement"/>
          </Col>
        </Row>
      </Container>
    )
  }
export default CompanyHandBookHowdothis

/* eslint-disable */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './styled.css';
import { Link } from 'gatsby';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import ServicesForm from '../../SevicesForm';

const ContactCompanyHandBook = ({ location }) => {
  const ContactCompanyHandBookImages = useStaticQuery(graphql`
    query ContactCompanyHandBookImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <Container className='contact--panel spb-8'>
      <Row>
        <Col lg='6' className='splitcol'>
          <h2>
            Get in touch to discuss <br /> how we can help your <br /> company
            operate better.
          </h2>

          <div className='team-grid'>
            <div>
              <div class='circle-wrapper'>
                <Image
                  className='img-fluid'
                  sizes={
                    ContactCompanyHandBookImages.louis.childImageSharp.sizes
                  }
                  alt='Louis Bester'
                />
              </div>
              <h6>Louis Bester</h6>
              <span>Product Owner</span>
            </div>
            <div>
              <div class='circle-wrapper'>
                <Image
                  className='img-fluid'
                  sizes={ContactCompanyHandBookImages.jay.childImageSharp.sizes}
                  alt='Jay Thomson'
                />
              </div>
              <h6>Jay Thomson</h6>
              <span>Managing Partner</span>
            </div>
            <div>
              <div class='circle-wrapper'>
                <Image
                  className='img-fluid'
                  sizes={
                    ContactCompanyHandBookImages.anton.childImageSharp.sizes
                  }
                  alt='Anton Moulder'
                />
              </div>
              <h6>Anton Moulder</h6>
              <span>Managing Partner</span>
            </div>
          </div>
        </Col>
        <Col lg='6'>
          <p>
            Please enter your details here and we'll be in touch to arrange a
            time.
          </p>
          <ServicesForm location={location} />
        </Col>
      </Row>
    </Container>
  );
};
export default ContactCompanyHandBook;
